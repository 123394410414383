<script setup>
import IconWarning from "@/Components/Icons/IconWarning.vue";
import IconArrowsRightLeft from "@/Components/Icons/IconArrowsRightLeft.vue";
import IconAngleRight from "@/Components/Icons/IconAngleRight.vue";
import IconCheck from "@/Components/Icons/IconCheck.vue";
import IconXMark from "@/Components/Icons/IconXMark.vue";
import IconExclamation from "@/Components/Icons/IconExclamation.vue";
import IconClock from "@/Components/Icons/IconClock.vue";
import IconEllipsisHorizontal from '@/Components/Icons/IconEllipsisHorizontal.vue';
import IconSquare from '@/Components/Icons/IconSquare.vue'
import IconCircle from '@/Components/Icons/IconCircle.vue'
import IconCheckCircle from '@/Components/Icons/IconCheckCircle.vue'
import IconMiniXMark from '@/Components/Icons/IconMiniXMark.vue'
import IconPlus from '@/Components/Icons/IconPlus.vue'

const props = defineProps({
    class: String,
    symbol: String,
    level: String,
    withBorder: {
        type: Boolean,
        default: false,
    }
})

const iconStyle = {
    'text-slate-500': props.level === undefined,
    'text-gray-500': props.level === 'default',
    'text-green-600': props.level === 'success',
    'text-blue-600': props.level === 'info',
    'text-yellow-600': props.level === 'warning',
    'text-red-600': props.level === 'danger',
}

const borderStyle = {
    'border-gray-500': props.level === 'default',
    'border-green-600': props.level === 'success',
    'border-blue-600': props.level === 'info',
    'border-yellow-600': props.level === 'warning',
    'border-red-600': props.level === 'danger',
}

</script>

<template>
    <template v-if="symbol">
        <div v-if="withBorder" class="inline-block" v-bind:class="props.class">
            <div class="inline-block border p-3 rounded-full" :class="borderStyle">
                <Icon :symbol="symbol" :level="level" :with-border="false"/>
            </div>
        </div>
        <template v-else>
            <IconWarning v-if="symbol === 'warning'" :class="iconStyle" v-bind:class="props.class"/>
            <IconArrowsRightLeft v-if="symbol === 'arrows-right-left'" :class="iconStyle" v-bind:class="props.class"/>
            <IconAngleRight v-else-if="symbol === 'angle-right'" :class="iconStyle" v-bind:class="props.class"/>
            <IconCheck v-else-if="symbol === 'check'" :class="iconStyle" v-bind:class="props.class"/>
            <IconXMark v-else-if="symbol === 'x-mark'" :class="iconStyle" v-bind:class="props.class"/>
            <IconExclamation v-else-if="symbol === 'exclamation'" :class="iconStyle" v-bind:class="props.class"/>
            <IconClock v-else-if="symbol === 'clock'" :class="iconStyle" v-bind:class="props.class"/>
            <IconEllipsisHorizontal v-else-if="symbol === 'ellipsis-horizontal'" :class="iconStyle" v-bind:class="props.class"/>
            <IconSquare v-else-if="symbol === 'square'" :class="iconStyle" v-bind:class="props.class"/>
            <IconSquare v-else-if="symbol === 'square'" :class="iconStyle" v-bind:class="props.class"/>
            <IconCircle v-else-if="symbol === 'circle'" :class="iconStyle" v-bind:class="props.class"/>
            <IconCheckCircle v-else-if="symbol === 'check-circle'" :class="iconStyle" v-bind:class="props.class"/>
            <IconMiniXMark v-else-if="symbol === 'mini-x-mark'" :class="iconStyle" v-bind:class="props.class"/>
            <IconPlus v-else-if="symbol === 'plus'" :class="iconStyle" v-bind:class="props.class"/>
        </template>
    </template>
</template>
